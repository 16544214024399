import Icon from 'components/Icon';
import RichText from 'components/RichText';

const Phone = ({ data }) => {
	const { id, codename, phone_number, description } = data;
	return (
		<div
			className='contact__item'
			data-kontent-item-id={id}
			data-kontent-element-codename={codename}
		>
			<Icon icon={'fa-thin fa-mobile-notch'} className='contact__icon' />
			<div className='contact__item-body'>
				{description && <RichText className='contact__description' data={description} />}
				{phone_number && <div className='contact__phone'>{phone_number}</div>}
			</div>
		</div>
	);
};

export default Phone;
